import axios from "axios";
import { createContext, useContext, useEffect } from "react";
import useTokenStore from "store/tokenStore";
import { today } from "utils";

const LastActivityContext = createContext();

export const LastActivityProvider = ({ children }) => {
	const token = useTokenStore((state) => state.accessToken);

	useEffect(() => {
		const sendLastActivity = () => {
			const data = {
				records: [
					{
						created_at: today,
						app_type: "web",
					},
				],
			};

			const url = `${process.env.REACT_APP_API_BASE_URL}/record-last-activity`;
			axios.post(url, data, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
		};

		window.addEventListener("beforeunload", sendLastActivity);

		return () => {
			window.removeEventListener("beforeunload", sendLastActivity);
		};
	}, [token]);

	return (
		<LastActivityContext.Provider value={{}}>
			{children}
		</LastActivityContext.Provider>
	);
};

export const useLastActivity = () => {
	return useContext(LastActivityContext);
};
