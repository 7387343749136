// UTILITY FUNCTIONS

/**
 * Today's local date
 * ----------------
 */
export const today = new Intl.DateTimeFormat("en-CA", {
	dateStyle: "short",
	timeStyle: "medium",
	hour12: false,
})
	.format(new Date())
	.replace(/,/g, "");

/**
 * Format currency
 * ----------------
 */
export function formatCurrency(numberValue, lag = "en-US") {
	const formatter = new Intl.NumberFormat(lag, {
		style: "currency",
		currency: "USD",
	});
	return formatter.format(numberValue);
}

/**
 * Check if a string starts with any of the prefixes in the array
 * ----------------
 */
export const startsWithAny = (string, prefixes) => {
	if (!string) return false;
	return prefixes.some((prefix) => string.startsWith(prefix));
};

export const sleep = (ms = 1000) =>
	new Promise((resolve) => setTimeout(resolve, ms));

export const getBrowser = () => {
	let userAgent = navigator.userAgent;
	let browserName;

	if (userAgent.match(/chrome|chromium|crios/i)) {
		browserName = "chrome";
	} else if (userAgent.match(/firefox|fxios/i)) {
		browserName = "firefox";
	} else if (userAgent.match(/safari/i)) {
		browserName = "safari";
	} else if (userAgent.match(/opr\//i)) {
		browserName = "opera";
	} else if (userAgent.match(/edg/i)) {
		browserName = "edge";
	} else {
		browserName = "No browser detection";
	}
	return browserName;
};

export const getOS = () => {
	let userAgent = window.navigator.userAgent,
		platform =
			window.navigator?.userAgentData?.platform || window.navigator.platform,
		macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
		windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
		iosPlatforms = ["iPhone", "iPad", "iPod"],
		os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = "MacOS";
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = "iOS";
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = "Windows";
	} else if (/Android/.test(userAgent)) {
		os = "Android";
	} else if (/Linux/.test(platform)) {
		os = "Linux";
	}
	return os;
};

export const getDevice = () => {
	let userAgent = window.navigator.userAgent,
		platform =
			window.navigator?.userAgentData?.platform || window.navigator.platform,
		macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
		windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
		iosPlatforms = ["iPhone", "iPad", "iPod"],
		device = null;
	if (
		macosPlatforms.indexOf(platform) !== -1 ||
		windowsPlatforms.indexOf(platform) !== -1 ||
		/Linux/.test(platform)
	) {
		device = "PC/Desktop";
	} else if (
		iosPlatforms.indexOf(platform) !== -1 ||
		/Android/.test(userAgent)
	) {
		device = "Mobile/iPad/Tablet";
	}
	return device;
};

export const deviceIdentifiers = () => {
	return {
		os: getOS(),
		device: getDevice(),
		browser: getBrowser(),
	};
};

export const deleteEmptyObjects = (obj) => {
	Object.keys(obj).forEach((key) => {
		const nullish = [null, "", undefined, NaN, "null", "undefined", "NaN"];
		if (nullish.includes(obj[key])) {
			delete obj[key];
		}

		// if (obj[key] === null || obj[key] === "" || obj[key] === undefined) {
		// 	delete obj[key];
		// }
	});
	return obj;
};

export const getHost = () => {
	if (!window.location.origin) {
		return (
			window.location.protocol +
			"//" +
			window.location.hostname +
			(window.location.port ? ":" + window.location.port : "")
		);
	}
	return window.location.origin;
};

export const errors = (error) => {
	let errorMessage = error.response ? error.response : error.message;
	return errorMessage;
};

export const fetchConfig = {
	retry: 2,
	staleTime: 180000,
	refetchOnWindowFocus: false,
	cacheTime: 180000,
};

export const delay = (s) => new Promise((res) => setTimeout(res, s * 1000));

export const defaultPwd = "Ak3llo#";

export const MAX_IMAGE_SIZE = 2; //MB

export const MAX_PDF_SIZE = 10000; //MB

export const dataThatCannotBeRecovered = [
	"Saved preferences",
	"Purchased books",
	"Subscriptions made",
	"History of books read",
	"Reading progress",
];

export const phoneRegex =
	/(\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/;

export const activeCurrencies = {
	USD: {
		code: "USD",
		label: "USD",
		symbol: "$",
		country: "United States",
	},
	ZWL: {
		code: "ZWL",
		label: "ZiG",
		symbol: "ZiG",
		country: "Zimbabwe",
	},
};

export const getMoneyPrefix = (currencyCode) => {
	if (currencyCode === activeCurrencies.USD.code) {
		return `${activeCurrencies.USD.label}${activeCurrencies.USD.symbol}`;
	}
	return `${activeCurrencies.ZWL.symbol}`;
};

export const getActiveCurrencyCodeLabel = (currencyCode) => {
	return activeCurrencies[currencyCode].label;
};

export const getActiveCurrencySymbol = (currencyCode) => {
	return activeCurrencies[currencyCode].symbol;
};

export const activePaymentMethods = {
	paypal: {
		name: "Paypal",
		label: "PayPal",
	},
	ecocashUsd: {
		name: "Ecocash USD",
		label: "Ecocash (USD)",
	},
	ecocashZwl: {
		name: "Ecocash",
		label: "Ecocash (ZiG)",
	},
	stars: {
		name: "Stars",
		label: "Redeem Stars",
	},
};

export const getPaymentMethodLabel = (paymentMethodName) => {
	const paymentMethod = Object.values(activePaymentMethods).find(
		(method) => method.name === paymentMethodName
	);
	return paymentMethod.label;
};

export const currencyCodes = [
	{
		country: "Zimbabwe",
		currency_code: "ZWL",
		currencyCodeLabel: "ZiG",
	},
	{
		country: "United States",
		currency_code: "USD",
		currencyCodeLabel: "USD",
	},
];
