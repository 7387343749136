import AddBook from "components/AddBook";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { Toaster } from "react-hot-toast";
import Routes from "Routes";
import AddUser from "components/AddUser";
import AddCategory from "components/AddCategory";
import AddCollection from "components/AddCollection";
import AddSubscriptionPlan from "components/AddSubscriptionPlan";
import AddRate from "components/AddRate";
import UploadBooks from "components/UploadBooks";
import AddSubjectGroup from "components/AddSubjectGroup";
import UploadUsers from "components/UploadUsers";
import { LastActivityProvider } from "providers/LastActivityProvider";

const queryClient = new QueryClient();

const App = () => {
	const history = useHistory();

	return (
		<Router history={history}>
			<QueryClientProvider client={queryClient}>
				<LastActivityProvider>
					<ToastProvider
						autoDismiss
						autoDismissTimeout={2000}
						placement="top-center"
					>
						<Router>
							<Routes />
						</Router>
						<AddBook />
						<AddUser />
						<AddCategory />
						<AddCollection />
						<AddSubscriptionPlan />
						<AddRate />
						<UploadBooks />
						<UploadUsers />
						<AddSubjectGroup />
						<Toaster
							toastOptions={{
								style: { zIndex: "99999" },
								success: {
									className:
										"text-akgreenest border border-gray-200 rounded-full",
								},
								error: {
									className: "text-red-700 border border-gray-200 rounded-full",
								},
							}}
						/>
					</ToastProvider>
				</LastActivityProvider>
			</QueryClientProvider>
		</Router>
	);
};

export default App;
